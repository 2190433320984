import Header from "../Header";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import AudioPlayer from "./AudioPlayer";

function VIP() {
  // ===========CLOUDINARY======================
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget({
      cloudName: 'dzpvitjve',
      uploadPreset: 'eeohdbag',
      resourceType: 'image', // Accept only images
      multiple: false, // Only single image upload
    }, function (error, result) {
      if (!error && result && result.event === "success") {
        console.log('Uploaded image URL: ', result.info.url);
        setUploadedUrl(result.info.url);
        setSuccessMessage('Your file has been successfully added.');
      }
    });
  }, []);
  // =================================

  const [error1, setError1] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // New state for checkbox

  const navigate = useNavigate();

  async function addData1(e) {
    e.preventDefault();
    setError1("");
    if (name && phone && email && termsAccepted) { // Check for termsAccepted
      console.warn(name, phone, email);
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('link', uploadedUrl);

      try {
        // let result = await fetch("http://127.0.0.1:8000/api/addVipAccessSubmitEvidencia", {
        let result = await fetch("https://juniorpersist.com/public/public/api/addVipAccessSubmitEvidencia", {
          method: 'POST',
          body: formData
        });
        window.location.href = '/PreOrder_success';
      } catch (error1) {
        console.log(error1);
      }
    } else {
      setError1("Error: Complete all fields, accept terms before submission.");
    }
  }

  return (
    <div>
      <div className="success_access_IA pb-4">
        <div className="success_access_IA_border_down">
          <div className="text0_success_access_IA col-sm-4 m-auto py-2">BOOK VIP ACCESS!</div>
        </div>

        <img src={'/images/testimonffy.png'} className='w-100 d-none d-sm-block' />
        <img src={'/images/testimonffy_mobile.png'} className='w-100 d-block d-sm-none' />

        <div className="barTitle_success_access_IA px-2">
          CLAIM YOUR <span className="text1_barTitle_success_access_IA">FREE</span> VIP BOOK ACCESS NOW
        </div>


        <center>
          <div className="mt-2 py-3">
            <div className="text2_success_access_IA">
              Complete the form below to get instant<br /> access to Persist, Persist, Persist.
            </div>
          </div>
        </center>

        <div className="mt-2">
          <div className="col-sm-5 m-auto mt-3 bg_vip" id="STARTED">
            <div className='justify-content-center px-5 py-4'>
              <div className='form'>
                <center>

                  {error1 && <div className='error_text2'>{error1}</div>}

                  {successMessage && (
                    <div>
                      <p className="text_succes_up_video">{successMessage}</p>
                    </div>
                  )}
                </center>
                <input className="input_part2_jett2" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett2" placeholder='Email Address' type="text" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <input className="input_part2_jett2" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="terms"
                    className="checkbox"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  <label htmlFor="terms" className="custom-checkbox"></label>
                  <label htmlFor="terms" className="checkbox-label">
                    I agree to be contacted on launch day (Oct. 15) to provide an Amazon review for Persist, Persist, Persist.
                  </label>
                </div>
                <div className="mt-3">

                  {/* <center>
                    {uploadedUrl ? null : (
                      <button className='btn_upload_video2' onClick={() => widgetRef.current.open()}>
                        <i className="bi bi-cloud-arrow-up-fill"></i> Attach file
                      </button>
                    )}
                  </center>
                  */}
                  <br />

                  <button onClick={(e) => addData1(e)} className='btn_part2_jett mt-2 py-1'>GAIN ACCESS NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <br></br><br></br>
      </div>

      <div className="bg97565 py-2 ">
        <div className="container">

          <div className="col-sm-8 m-auto py-3 ">
            <div className="row">

              <div className="col-sm-6 border_spread001 mb-4">
                <div className="px-3">
                  <div className="text0_spread">A Quick Note:</div>
                  <div className="text_spread_box009 mt-3">
                    IN THE WORLD OF BOOKS, <span className="text_spread_box004">AMAZON REVIEWS</span>  ARE EVERYTHING!
                  </div>
                  <div className="text0_spread mt-3">
                    Help Junior reach <strong>1000 reviews</strong>  by launch day—October 15th!
                    Your support would mean the world to us!
                  </div>
                  <br />
                </div>
                <div className="d-block d-sm-none">
                  <hr />
                </div>
              </div>
              <div className="col-sm-6 mb-4 ">
                <div className="px-3">
                  <div className="text_spread_box001 mb-2">HOW TO LEAVE A REVIEW ON AMAZON</div>
                  <div className="col-sm-8 text_spread_box010">
                    Note: To leave a review on Amazon, you must pre-order the book between today and Oct. 15.
                    Take advantage of this promotion before it expires.
                  </div>
                  <div className="py-3">
                    <span className="text1_spread_box001">$9,99</span>  <span className="text2_spread_box001 px-3">$2,99</span>
                    <a className="btn_spread003" target="_blank" href="https://www.amazon.com/dp/B0DG4R698Z?ref_=pe_93986420_775043100">PRE-ORDER EBOOK</a>
                    <div className="mt-4 col-sm-8">
                      <center>
                        <img src={'/images/testimonffyAmazon.png'} className="img_spread004" />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>




      <div className="bg-black ">
        <br></br>
        <div className="container col-sm-8 py-4 ">
          <center>
            <div className="jett_scrollmenu">
              <div className="col-sm-6 jett_scrollmenu_in">
                <div className="text_spread_box006">BOOK TRAILER</div>
                <div className="video_part_cont video_part_cont_new py-4">
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>



              <div className="col-sm-6 jett_scrollmenu_in">
                <div className="text_spread_box006">PRAISES</div>
                <div className="video_part_cont video_part_cont_new py-4">
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

            </div>


            <div className="col-sm-6 m-auto">
              <div className="new_audio0398 px-2 py-2">AUDIO EXCERPT</div>
              <div className="py-2">
                <AudioPlayer />
              </div>
            </div>

          </center>
        </div>

      </div>




    </div>
  );
}

export default VIP;
