import { Link } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import Join from '../Join'
import Video_Junior from '../Video_Junior'
import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useForm } from "react-hook-form";
// ==========
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Supporters from '../Supporters'
import Subscribe_layout from '../Subscribe_layout'
import AudioPlayer from './AudioPlayer'
const images = [
  "./images/building/1.png",
  "./images/building/2.png",
  "./images/building/3.png",
  "./images/building/4.png",
  "./images/building/5.png",
  "./images/building/6.png",
  "./images/building/7.png",
  "./images/building/8.png",
];


function Home() {

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState("")

  async function addData(e) {
    e.preventDefault();
    setError("")
    if (name && phone && email) {

      console.warn(name, phone, email)
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      try {
        let result = await fetch("https://juniorpersist.com/public/public/api/addGainAccess", {
          method: 'POST',
          body: formData
        });
        navigate('/Preview')
      } catch (error) {
        console.log(error);
      }
    } else {
      setError("Error: all fields required");
    }
  }


  // ======Model
  const [show, setShow] = useState(false);

  // =================================

  // ================================Model======================
  const [showModal, setShowModal] = useState(null);

  const handleShow = (modalName) => {
    setShowModal(modalName);
  };

  const handleClose = () => {
    setShowModal(null);
  };
  return (

    <div>
      <Header />
      {/* ===========Slider */}
      <div className="slider_home_page_jett pb-5">
        <div className="">
          <div className="row">
            <div className="col-sm-5">
              <center>
                <div className="left_content">
                  <img className='image_slider' src={'/images/book/3.png'} />
                </div>
              </center>
            </div>
            <div className="col-sm-7">
              <div className="text_slider space10">

                <div className="col-sm-10 d-none d-sm-block mt-5">
                  <div className="text_slider1">BOOK RELEASE DATE: </div>
                  <div className="text_slider1"> OCTOBER 15, 2024</div>
                  <div className="text_slider2 my-4">
                    Persist, Persist, Persist.
                    <div className="text_slider5">The Haitian Boy, The American, and The Hidden Lesson</div>
                  </div>
                  <p className="text_slider3 col-sm-12 mb-4">
                    In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death. Growing up in the heart of poverty in Haiti...
                  </p>
                </div>

                <div className='btn_space_slider_home_page mt-2 d-none d-sm-block '>
                  <Link to={'/Millioncopyjourney'} className="btn_buy_book px-3 mobile_home_btn1 ">VIDEO SERIES</Link>
                  <a href="/BuyDetails" className="btn_preoreder px-4 mobile_home_btn1  mx-2">PRE-ORDER NOW</a>
                </div>

                {/* ====================================== */}
                <div className='btn_space_slider_home_page  d-block d-sm-none '>
                  <span className="btn_buy_book px-3 mobile_home_btn1 ">
                    LAUNCH DATE: OCTOBER 15, 2024
                  </span>
                  <a href="/BuyDetails" className="btn_preoreder px-4 mobile_home_btn1  mx-2">PRE-ORDER NOW</a>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>



      {/* ========================Part2 */}


      <div className='container part2 py-5  '>
        <div className='row col-sm-12 jett_content_part2_23'>
          <div className='col-sm-8 jett_block_double_slider'>


            <div className='text2_part22  text-black'>WATCH THE TRAILER</div>
            <div className='video_new8745'>
              <iframe className='video875' src="https://player.vimeo.com/video/1001246468" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div className="home_new_text1 space876 opacity-25 pt-4 mb-2">FANS & SUPPORTERS</div>
            <div className='space877'>
              <Supporters />
              <div className='mb-4 mt-4'>
                <Link to={'/BuyDetails'} className="btn_buy_book20 px-4 mobile_home_btn1 ">ORDER THE BOOK HERE</Link>
              </div>

            </div>

          </div>

          <div className='col-sm-4 jett_block_double_slider'>
            <div className='justify-content-center part2_right shadow-lg px-5 py-5 bg-white'>
              <div className=' top_part2_right'>
                <div className='text1_part2'>PURE MADNESS!</div>
                <div className='text2_part2'>DISCOVER WHAT’S INSIDE THE BOOK</div>
                <a href="#" onClick={() => handleShow('audio')}>
                  <img className='image_2' src={"/images/audio_home.png"} />
                </a>
              </div>
              {/* ================Form================ */}

              {/* <div className='form'>
                {error && <div className='error_text'>{error}</div>}
                <input className="input_part2_jett" placeholder='Name' type="text" id="name" required="" onChange={(e) => setName(e.target.value)} />
                <input className="input_part2_jett" placeholder='Phone' type="text" id="phone" required="" onChange={(e) => setPhone(e.target.value)} />
                <input className="input_part2_jett" placeholder='Email Address' type="email" id="email" required="" onChange={(e) => setEmail(e.target.value)} />
                <button type='submit' onClick={(e)=>addData(e)} className='btn_part2_jett mt-2 py-1'> GAIN ACCESS NOW</button>
              </div> */}

              <center>
                <div className='mb-2'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <a href="#" onClick={() => handleShow('pdf')}>
                        <img src={'/images/icons/kaptab/2.png'} className="kaptap_img" />
                        <div className="kaptab_text">PDF EXCERPT</div>
                      </a>
                    </div>
                    <div className='col-sm-6'>
                      <a href="#" onClick={() => handleShow('blurb')}>
                        <img src={'/images/icons/kaptab/4.png'} className="kaptap_img" />
                        <div className="kaptab_text">BOOK BLURB</div>
                      </a>
                    </div>
                  </div>
                </div>
                <a className='btn_home_jett_7864 px-3 py-1' href="https://lp.constantcontactpages.com/sl/mt3B9Qg/persistbook" target='_blank' >
                  READ FULL CHAPTER SAMPLE
                </a>
              </center>

              {/* =========Form=====================Part2-1 */}

            </div>
          </div>
        </div>

      </div>





      {/* ==================================================== */}
      <div className='bg-black py-4'>
        <div id='VIDEOS' className='part3 container col-sm-9 mt-4'>
          {/* <div className="text_header_jett_89 text-center px-3 text-black mt-2">IT'S SENSATIONAL!</div>
          <center>
            <div className='text2_part3 mb-3 opacity-50 col-sm-7 py-2'>
              Discover the latest insights from the author and the buzz around the book
            </div>
          </center> */}

          <div className='text2_part22  text-white text-center mb-2'>OUR LATEST VIDEOS</div>


          <div className=''>
            <div className='row'>

              <div className='col-sm-4 py-3 jett_block_double_slider'>
                <div className='part3_video_box shadow'>
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/957777985" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className='bg-white px-4 py-2'>
                    <span>Readers' Reactions </span><br />
                    <span className='text_box_video_part3'>"It's an absolute masterpiece."</span>
                  </div>
                </div>
              </div>





              <div className='col-sm-4 py-3 jett_block_double_slider'>
                <div className='part3_video_box shadow'>
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/956163210" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className='bg-white px-4 py-2'>
                    <span> Review from University Professor</span><br />
                    <span className='text_box_video_part3'>Dr. Rumu's reaction to the book</span>
                  </div>
                </div>
              </div>



              <div className='col-sm-4 py-3 jett_block_double_slider'>
                <div className='part3_video_box shadow'>
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/957451789" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className='bg-white px-4 py-2'>
                    <span>International Event</span><br />
                    <span className='text_box_video_part3'>Reflecting on the book "Persist, Persist, Persist."</span>
                  </div>
                </div>
              </div>

              <div className='col-sm-4 py-3 jett_block_double_slider'>
                <div className='part3_video_box shadow'>
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/950825473" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className='bg-white px-4 py-2'>
                    <span>A Young Professional's Reaction</span><br />
                    <span className='text_box_video_part3'>Serenity's impression of "Persist, Persist, Persist."</span>
                  </div>
                </div>
              </div>


              <div className='col-sm-4 py-3 jett_block_double_slider'>
                <div className='part3_video_box shadow'>
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/950826541" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className='bg-white px-4 py-2'>
                    <span>Life Lessons from Junior  </span><br />
                    <span className='text_box_video_part3'>Life lessons from the Haiti earthquake.</span>
                  </div>
                </div>
              </div>

              <div className='col-sm-4 py-3 jett_block_double_slider'>
                <div className='part3_video_box shadow'>
                  <iframe className='iframe_part3' src="https://player.vimeo.com/video/983285757" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                  <div className='bg-white px-4 py-2'>
                    <span>Persist, Persist, Persist.</span><br />
                    <span className='text_box_video_part3'>You have what it takes</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='text-center mt-2'>
            <a href="/Video" className="h4 opacity-25">VIEW MORE</a>
          </div>
        </div>
      </div>
      {/* ==================================================== */}

      <div className="py-3 share_the_book" >
        <center>
          <div className='mb-5'>
            <div className='text2_part22  text-black text-center mt-5 '>OUR GOAL IS TO REACH A MILLION READERS!</div>
            <div className='f9086765'>
              <a href={'/SpreadTheWord'} className='btn_part2_jett  py-1 px-4'>SPREAD THE WORD</a>
            </div>
          </div>



          <img src={'/images/share_the_book.png'} className='w-100 d-none d-sm-block' />
          <img src={'/images/share_the_book_mobile.png'} className='w-100 d-block d-sm-none' />

          <div className='row col-sm-7 m-auto py-5'>
            <div className='col-sm-4 border_left001 mb-4'><a className='btn_share_the_book' href='/BuyDetails'>GET A BOOK FOR A FRIEND</a></div>
            <div className='col-sm-4 border_left001 mb-4'><a className='btn_share_the_book px-5' href='/SpreadTheWord'>SPREAD THE WORD</a></div>
            <div className='col-sm-4 mb-4'><a className='btn_share_the_book' href='/millioncopyjourney'>WATCH OUR VIDEO SERIES</a></div>
          </div>
        </center>
      </div>

      {/* ========================Part3 */}








      {/* ==========================Part 4============================= */}



      {/* <div className='part6_header col-sm-7 shadow'>
        <div className='part6_header2 py-3'>
          <div className='text1_part6'>ATTENTION!</div>
        </div>
      </div> 
      <Join />
      */}




      {/* 
      <div className='part6_header bg-black col-sm-7 shadow mb-5 space8479l'>
        <div className='part6_header2 py-3'>
          <div className='text1_part6 '>FUEL YOUR AMBITIONS</div>
        </div>
      </div>
      <br />   <br />
      <a href='https://ayisyenkapab.com/persist-collection/' target='_blank'>
        <img className='w-100' src='/images/new/h_shop.png' />
      </a>
      <div className='pt-1'></div> */}




      <Footer />



      {/* ================Modal=---------------------- */}

      <Modal show={showModal === 'pdf'} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Book Excerpt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="resume_book space0098">
            <center>
              <div className="col-sm-8">
                <div className='blog_scrol_jett'>
                  <img className="image_book_mobile jett_scrollmenu_in" src={'/pdf/img_book/home_persist-1.png'} />
                  <img className="image_book_mobile jett_scrollmenu_in" src={'/pdf/img_book/home_persist-2.png'} />
                </div>
              </div>
            </center>
          </div>
          {/* ====== */}



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===================================== */}

      <Modal show={showModal === 'audio'} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Audio Excerpt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-black px-2 py-2">
            <AudioPlayer />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ===================================== */}

      <Modal show={showModal === 'blurb'} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Book Blurb</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="sum_book_7645 " id="PERSIST">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-sm-10 ">
                <div className="buy_new_1 pt-2">PERSIST, PERSIST, PERSIST.</div>
                <div className="">
                  <div className="buy_new_2 ">The Haitian Boy, The American, </div>
                  <div className="buy_new_2 ">and The Hidden Lesson</div>

                  <div className="book_part3_text mt-3 ">

                    In a world that often dismisses the pursuit of dreams as a mere luxury, for Junior, it was an all-out war between life and death.
                    <br /><br />
                    Growing up in the heart of poverty in Haiti, a world vividly described in this memoir, Junior embarked on an unprecedented journey—from surviving a heart-wrenching car crash to desperate street begging in the Dominican Republic. Then came the Haiti earthquake, a relentless force that snuffed out over 200k lives in less than 60 seconds.
                    <br /><br />
                    And if you think you've heard it all, think again. What lies ahead in these pages takes “persistence” to a whole new dimension—a story so gripping, so raw, that you need to read it to believe it.
                    <br /><br />
                    "Persist, Persist, Persist" is not just a memoir; it's an electrifying, can't-put-it-down chronicle that immerses you in Junior's thrilling journey and unveils Haiti's narrative in a revolutionary light. A timeless tale of obsession, survival, and self-invention, intricately woven with the unforgiving twists of fate.
                    <br /><br />
                    Brace yourself for a narrative that will make you lose track of time and demand to be shared with everyone you know.


                  </div>

                  <div className="mt-4">
                    <img src={'/images/buy_footer1234.png'} className="persist_img_page8474" />
                  </div>

                </div>

              </div>
            </div>

          </div>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* =========================================== */}

    </div>
  )
}


export default Home